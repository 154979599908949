<template>
  <div class="wrap">
    <div class="content bg">
      <div class="news">
        <h2>助力军运会 英特沃德数字病区系统打造医疗保障新模式<span>2019-10-23</span></h2>
        <div class="new-con">
          <p>作为武汉军运会唯一保障病区，中部战区总医院特别开辟了“军运会住院专区”，该专区全面采用了英特沃德医疗科技研发的CDR+数字病区全套软硬件系统。目前，英特沃德CDR+数字病区全系统在军运会住院专区已经开始投入使用，全系统受到军运会组委会、解放军联勤保障部、武汉市政府、医院领导的一致认可和好评。</p>
          <p><img src="../../assets/images/news/new1-img1.png" alt=""></p>
          <p class="tac">中部战区总医院“军运会住院专区”</p>
          <p><img src="../../assets/images/news/new1-img2.png" alt=""></p>
          <p>不同于普通医院病区，英特沃德的CDR+数字病区系统通过自行研发的软件系统（共12大子系统）集成一系列硬件设施，打造病区数字化、智能化、自动化医疗及护理服务平台，将医生工作站、护士工作站、病房三大板块紧密连接在一起，对病区病人各项生理指标、诊疗状态等进行集中监控及预警，医护人员不必时时刻刻在医患通道上来回奔波，同时在普通病床床旁实现诸如医嘱执行、预约检查等各项医疗服务应用，在降低医护人员劳动强度、提升患者就医体验、降低医患矛盾方面效果显著。</p>
          <p>下面就带大家看一看英特沃德CDR+数字病区系统在“军运会住院专区”的具体应用：</p>
          <p><img src="../../assets/images/news/new1-img3.png" alt=""></p>
          <p>在CDR+数字病区里，每张病床枕下都放置了24小时无扰式生命体征监护仪，病人无须穿戴任何手环或监测探头，躺在床上不知不觉中即能监测出心率、血氧、血压、呼吸等各项生命体征。监测指数会直接显示在护士站的病区中央监控中心系统的大屏上，如有异常情况，屏幕会通过不同颜色的光电信号发出预警，方便护士随时掌握病区各个病人的实时动态，大大节省护士的时间、体力成本。</p>
          <p>床旁配备的心电监护仪也通过系统连接到护士站的病区中央监护中心，方便护士随时监护重症病人的心率波形、心电图等数据。</p>
          <p><img src="../../assets/images/news/new1-img4.png" alt=""></p>
          <p>什么时候输液、什么时候拔针，这件“小事”经常困扰着病人家属和护士，有了英特沃德的自动输液监控系统，就能够准确地控制每位病人的输液滴速、监控输液进度。每位病人床头配有一台自动输液泵，输液泵通过软件系统连接到护士站的病区中央监护中心，护士可随时监护，实现“零”陪护、全自动输液。这个自动输液泵还有加热功能哟~</p>
          <p><img src="../../assets/images/news/new1-img5.png" alt=""></p>
          <p class="tac">中部战区总医院英特沃德数字病区的床头终端系统为病人疹疗</p>
          <p>除了监测医疗数据，包括主治医生信息、病例、检查预约、医嘱，药品价格等一系列信息在床头的平板上都能找到。这是英特沃德自主研发制作的多功能数字化床头终端，终端上的内容对医、护、患三方开放，让所有医疗过程公开透明，既能够减少医患纠纷，也方便医、护、患三方随时查询了解医疗进度。</p>
          <p>终端上的医护患三方对话系统能够提供患者在线呼叫护士站，护士呼叫医生，医生呼叫护士，医生呼叫患者等多方交流系统，可实时在线语音视频，为床旁会诊、床旁协助医疗提供了可能。</p>
          <p><img src="../../assets/images/news/new1-img6.png" alt=""></p>
          <p>病房里病人的输液进度、生命体征等数据最终都能够汇总到护士站安装的输液监控系统和病区中央监护中心，让“军运会住院专区”真正实现数字化管理。</p>
          <p><img src="../../assets/images/news/new1-img7.png" alt=""></p>
          <p>2018年12月，英特沃德CDR+数字病区项目在武汉软件新城启动。提升患者就医体验，降低护理人员工作强度，打造数字化、智能化、自动化医疗及护理平台，英特沃德CDR+数字病区项目要做的是建设未来医院。而这次助力武汉军运会唯一保障病区——中部战区总医院完成现代化升级改造，就是对该系统先进性的极大肯定。未来，英特沃德CDR+数字病区系统将会推向更多国内高端学术带头医院，进一步提升中国医疗装备数字化、自动化、人工智能化的水平。</p>
        </div>
        <div class="source"><a href="https://mp.weixin.qq.com/s/_4MsRqsttSLsaZMHLVUebw" target="_blank">阅读原文</a></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'News'
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url("../../assets/images/banner4.png") no-repeat center;
  text-align: center;
  overflow: hidden;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: url("../../assets/images/banner4-icon.png") no-repeat center;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.bg {
  background: #fafafa;
}
.content {
  position: relative;
  padding: 60px 0;
  margin-top: 76px;
  .news {
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    h2 {
      text-align: center;
      margin-bottom: 30px;
      span {
        display: block;
        margin: 10px auto 0;
        font-size: 14px;
      }
    }
    .new-con {
      width: 80%;
      margin: 0 auto;
      p {
        margin-bottom: 30px;
        text-indent: 2em;
        line-height: 2.2;
        img {
          display: block;
          margin: 0 auto;
        }
        &.tac{
          text-align: center;
          margin-top: -30px;
        }
      }
    }
    .source a {
      font-size: 12px;
      color: #434343;
    }
  }
}
</style>
